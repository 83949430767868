import BottomNav from '@blok/bottom-nav'
import TopNav from '@blok/top-nav'
import GalleryModule from '@components/integrations/gallery-module'
import Layout from '@components/layout'
import { PageContextProvider } from '@context'
import { formatLocalizedLink, removeBothEndSlashes } from '@helpers'
import { ThemeProvider, makeStyles } from '@material-ui/styles'
import { ContentContainer, H3, PageContainer } from '@system'
import ACC_THEME from '@themes'
import { Link, graphql } from 'gatsby'
import React from 'react'

const useStyles = makeStyles(() => ({
  link: {
    color: ACC_THEME.palette.common.black,
    display: 'flex',
    fontWeight: ACC_THEME.typography.fontWeightBold,
    justifyContent: 'center',
    margin: '0 auto',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0',
    textAlign: 'center',
  },
}))

const IntegrationCategory = ({ data, location, pageContext }) => {
  const classes = useStyles()

  const prepareStory = () => {
    const bottomNav = Object.assign({}, data.bottomNav)
    const topNav = Object.assign({}, data.topNav)

    bottomNav.content = JSON.parse(bottomNav.content)
    topNav.content = JSON.parse(topNav.content)

    return { bottomNav, topNav }
  }

  let {
    bottomNav: { content: bottomNav },
    topNav: { content: topNav },
  } = prepareStory()

  const metaData = {
    description: '',
    title: '',
  }

  const localizedIndexPath = formatLocalizedLink(
    pageContext.integrationIndexPath
  )
  const indexPath = removeBothEndSlashes(localizedIndexPath)

  const integrations = data.integrations.nodes
  const dedupedIntegrations = integrations.filter((obj, index) => {
    return index === integrations.findIndex((o) => obj.name === o.name)
  })

  return (
    <PageContextProvider {...pageContext} location={location}>
      {topNav && <TopNav blok={topNav}></TopNav>}
      <Layout metaData={metaData}>
        <ThemeProvider theme={ACC_THEME}>
          <PageContainer pb={6} pt={6}>
            <ContentContainer>
              <H3
                className={classes.title}
                component="h1"
              >{`${pageContext.integrationCategory} Integrations`}</H3>
            </ContentContainer>
          </PageContainer>
          <GalleryModule
            fullSlug={indexPath}
            integrations={dedupedIntegrations}
            showAll={true}
          />
          <PageContainer pb={6}>
            <ContentContainer>
              <Link className={classes.link} to={localizedIndexPath}>
                View all integrations
              </Link>
            </ContentContainer>
          </PageContainer>
        </ThemeProvider>
      </Layout>
      {bottomNav && <BottomNav blok={bottomNav}></BottomNav>}
    </PageContextProvider>
  )
}

export default IntegrationCategory

export const query = graphql`
  query (
    $bottomNavId: String!
    $integrationCategory: String!
    $topNavId: String!
  ) {
    bottomNav: storyblokEntry(id: { eq: $bottomNavId }) {
      id
      name
      created_at
      uuid
      slug
      field_component
      full_slug
      lang
      content
      is_startpage
      parent_id
      group_id
      first_published_at
      alternates {
        full_slug
        id
        name
        parent_id
        slug
        published
        is_folder
      }
    }
    integrations: allProducts(
      filter: { categories: { in: [$integrationCategory] } }
    ) {
      nodes {
        categories
        id
        logo
        name
      }
    }
    topNav: storyblokEntry(id: { eq: $topNavId }) {
      id
      name
      created_at
      uuid
      slug
      field_component
      full_slug
      lang
      content
      is_startpage
      parent_id
      group_id
      first_published_at
      alternates {
        full_slug
        id
        name
        parent_id
        slug
        published
        is_folder
      }
    }
  }
`

import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Box } from '@material-ui/core'
import { Link } from 'gatsby'
import { TextSm, H4, Button } from '@system'
import convertUrlToHttps from '@helpers/convert-url-to-https'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    },
  },
  image: {
    height: '96px',
    flexShrink: 0,
    padding: '20px',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      height: '144px',
    },
    '&>div': {
      height: 'inherit',
    },
    '&>img': {
      width: '100%',
      maxWidth: '200px',
      margin: '0 auto',
      objectFit: 'contain',
      maxHeight: '100px',
    },
  },
  companyDetails: {
    padding: '0 0 8px 14px',
  },
  companyName: {
    color: theme.palette.text.secondary,
    display: 'block',
    fontWeight: theme.typography.fontWeightBold,
    padding: '8px 0',
  },
  category: {
    color: theme.palette.text.secondary,
    display: 'block',
    paddingTop: '5px',
  },
  noResultText: {
    display: 'block',
    color: theme.palette.text.tertiary,
  },
  logos: {
    paddingRight: '8px',
    '&>img': {
      height: '24px',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: '24px',
      '&>img': {
        height: '32px',
      },
    },
  },
  linkStyle: {
    textDecoration: 'none',
    display: 'block',
    border: '1px solid #dddddd',
    borderRadius: '5px',
    height: '100%',
  },
  outerContainer: {
    paddingBottom: '4rem',
  },
  showMore: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    color: 'white',
    backgroundColor: 'black',
    borderRadius: 4,
  },
}))

const GalleryModule = ({ fullSlug, integrations, showAll = false }) => {
  const classes = useStyles()

  const [count, setCount] = useState(showAll ? integrations.length : 12)

  const compare = (firstObj, secondObj) => {
    const nameA = firstObj.name.toUpperCase()
    const nameB = secondObj.name.toUpperCase()
    let comparison = 0
    if (nameA > nameB) {
      comparison = 1
    } else if (nameA < nameB) {
      comparison = -1
    }
    return comparison
  }

  return (
    <PageContainer>
      <ContentContainer className={classes.outerContainer}>
        <Grid container spacing={2}>
          {integrations?.length > 0 ? (
            integrations
              .sort(compare)
              .slice(0, count)
              .map((story) => {
                return (
                  <Grid item xs={6} md={4} lg={4} key={story.id}>
                    <Link
                      to={`/${fullSlug}/${story.name
                        .replace(/[^A-Z0-9]+/gi, '-')
                        .toLowerCase()}`}
                      className={classes.linkStyle}
                    >
                      <Box className={classes.card}>
                        <Box className={classes.image}>
                          {story?.logo && (
                            <img
                              src={convertUrlToHttps(story.logo)}
                              alt={`${story.name} logo`}
                            />
                          )}
                        </Box>
                        <Box className={classes.companyDetails}>
                          {story?.name && (
                            <TextSm className={classes.companyName}>
                              {story.name}
                            </TextSm>
                          )}
                          {story?.categories?.map((category) => (
                            <TextSm className={classes.category} key={category}>
                              {category}
                            </TextSm>
                          ))}
                        </Box>
                      </Box>
                    </Link>
                  </Grid>
                )
              })
          ) : (
            <H4 className={classes.noResultText}>
              No result found. Please try again!
            </H4>
          )}
        </Grid>
        {!showAll && integrations?.length >= count && (
          <Box className={classes.showMore}>
            <Button
              className={classes.button}
              onClick={() => setCount(count + 12)}
            >
              Show More
            </Button>
          </Box>
        )}
      </ContentContainer>
    </PageContainer>
  )
}
export default GalleryModule
